html, body {
    @include breakpoint(m) {
        height: 100%;
    }
}

body {
    font-optical-sizing: auto;
    font-style: normal;

    @include breakpoint(m) {
        background-image: url(/img/bg.png);
        background-size: cover;
        background-attachment: fixed;
        background-position: bottom;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

h1 {
    font-weight: 600;
    
    @include breakpoint(m) {
        margin-top: 2rem;
    }
}

h2 {
    font-weight: 300;
    margin-top: 0.25rem;
}

h3 {
    font-weight: 300;
    margin-top: 1rem;

    b {
        font-weight: 500;
    }
}

p:last-child {
    margin: 1.5rem 0 0.5rem 0;
}

a {
    color: #9a2d26;
}

.nd-container {
    background: #fff;
    position: relative;
    text-align: center;

    @include breakpoint(m) {
        box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.5);
    }
}

.nd-me {
    width: 5rem;
    border-radius: 50%;
    
    @include breakpoint(m) {
        width: 7rem;
        border: 4px solid #fff;
        position: absolute;
        top: -3.5rem;
        left: calc(50% - 3.5rem)
    }
}

.nd-button {
    background: #9a2d26;
    display: inline-block;
    padding: 0.5rem 0.75rem 0.6rem 0.5rem;
    color: #fff;
    text-decoration: none;
    line-height: 1;
}