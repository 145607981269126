// ==========================================================================
// Website or App
// ==========================================================================

// Variables
@import "styles/variables";
@import url('https://fonts.googleapis.com/css2?family=Platypi:ital,wght@0,300..800;1,300..800&display=swap');

// Import Base
@import "~@getbase/base/scss/_mixins";
@import "~@getbase/base/scss/core";
@import "~@getbase/base/scss/code";
@import "~@getbase/base/scss/forms";
@import "~@getbase/base/scss/tables";
@import "~@getbase/base/scss/containers";
@import "~@getbase/base/scss/grid";
@import "~@getbase/base/scss/typography";
@import "~@getbase/base/scss/headings";

// Website Styles
@import "styles/main";

// Helpers
@import "~@getbase/base/scss/horizontal-spacers";
@import "~@getbase/base/scss/vertical-spacers";
@import "~@getbase/base/scss/spacers";
@import "~@getbase/base/scss/typography-helpers";
@import "~@getbase/base/scss/display-helpers";
@import "~@getbase/base/scss/flex-helpers";
@import "~@getbase/base/scss/position-helpers";